import React, { Component } from 'react';
import Webcam from "react-webcam";

const videoConstraints = {
    width: 720,
    height: 1280,
    facingMode: "user"
};

const WebcamCapture = (props) => {
    const webcamRef = React.useRef(null);

    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            console.log(imageSrc)
            props.parent.captureFromCamera(imageSrc);
        },
        [webcamRef]
    );

    const batal = React.useCallback(
        () => {
            props.parent.setState({ page: 1, is_camera_active: false, })
        },
        [webcamRef]
    );

    return (
        <>
            <Webcam
                audio={false}
                // height={1280}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                // width={720}
                videoConstraints={videoConstraints}
            />
            <div style={{ paddingTop: 16 }}>
                <button name="process" className="tombol" onClick={capture}>Ambil Foto</button>&nbsp;
                <button name="process" className="tombol" onClick={batal}>Batal</button>
            </div>
        </>
    );
};

export default WebcamCapture;